import { memo } from "react";
import styles from "./text.style";

interface Props {
  children?: any;
  style?: any;
  allCaps?: boolean;
}

const Text = ({ children, style, allCaps }: Props) => {
  return (
    <div style={{ ...styles.textStyle, ...style }}>
      {allCaps ? children.toUpperCase() : children}
    </div>
  );
};

export default memo(Text);
