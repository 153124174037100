import { createContext, useEffect, useState } from "react";
import "./App.css";
import HeaderBar from "./components/HeaderBar";
import router, {
  AppScreen,
  JOIN_GAME_SCREEN,
  LANDING_SCREEN,
} from "./pages/router";
import useSockets from "./useSockets";

export const TESTING_UI = false;

export const NavigationContext = createContext(undefined as any);
const storedUsername = localStorage.getItem("username");

const App = () => {
  const [username, setUsername] = useState<string>(storedUsername || "");
  const [roomCode, setRoomCode] = useState<string>("");
  const [screen, setScreen] = useState<string>(JOIN_GAME_SCREEN);

  const socketProps = useSockets(roomCode, username, setScreen);
  const { isConnected, closeConnection } = socketProps;

  useEffect(() => {
    if (isConnected) {
      setScreen(LANDING_SCREEN);
    }
  }, [isConnected]);

  const pageProps = {
    username,
    setUsername,
    roomCode,
    setRoomCode,
    ...socketProps,
  };

  const pageData = router.find(
    (pageItem: AppScreen) => pageItem.name === screen
  );
  if (!pageData) return null;
  const PageComponent = pageData.page;

  return (
    <div>
      <NavigationContext.Provider value={{ screen, setScreen, username }}>
        <HeaderBar {...{ closeConnection }} title={pageData.header} />
        <PageComponent {...pageProps} />
      </NavigationContext.Provider>
    </div>
  );
};

export default App;
