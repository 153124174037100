import colors from "../../utils/colors";

export const TOP_BAR_HEIGHT = 70;
const paddingHoriztonal = 20;

export default {
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: TOP_BAR_HEIGHT,
    paddingLeft: paddingHoriztonal,
    backgroundColor: colors.topBar,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 16,
  },
  title: {
    fontSize: "1.3em",
    fontWeight: 600,
    color: "white",
  },
  menuButton: {
    position: "absolute",
    right: paddingHoriztonal,
    transform: "scale(.75, .75)",
  },
} as any;
