import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, memo, useState } from "react";
import colors from "../../utils/colors";
import styles from "./button.style";

export const PRIMARY_BUTTON = "primary";

interface Props {
  title?: string;
  onClick?: () => void;
  style?: CSSProperties;
  children?: any;
  disabled?: boolean;
  icon?: IconDefinition;
  type?: string;
}

const Button = ({
  title,
  onClick,
  style,
  children,
  disabled,
  icon,
  type,
}: Props) => {
  const [isClickedIn, setClickedIn] = useState<boolean>(false);
  let baseStyle = style;

  if (type === PRIMARY_BUTTON) {
    baseStyle = { ...styles.primaryBarButton, ...style };
  }

  const dynamicStyles = {
    transition: "transform .05s",
    borderWidth: 0,
    opacity: disabled ? 0.5 : 1,
    ...baseStyle,
  };
  const buttonStyle = isClickedIn
    ? {
        transform: "scale(0.97, 0.97) translateY(2px)",
        boxShadow: `1px 1px 0px black, 0 0 10px ${colors.blue}, 0 0 0px ${colors.blue}`,
        ...dynamicStyles,
      }
    : {
        boxShadow: `1px 1px 0px black, 0 0 10px ${colors.blue}, 0 0 0px ${colors.blue}`,
        ...dynamicStyles,
      };
  return (
    <button
      disabled={disabled}
      onPointerDown={() => setClickedIn(true)}
      onPointerUp={() => setClickedIn(false)}
      onMouseLeave={() => setClickedIn(false)}
      onClick={onClick}
      style={buttonStyle}
    >
      {!!title && <div>{title}</div>}
      {!!children && children}
      {!!icon && (
        <FontAwesomeIcon size={"2x"} color={colors.buttonText} icon={icon} />
      )}
    </button>
  );
};

export default memo(Button);
