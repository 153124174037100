import Button from "../Button";
import styles from "./topBarMenu.style";

interface Props {
  data?: { name: string; onClick: () => void }[];
  hideMenu: () => void;
}

const TopBarMenu = ({ data, hideMenu }: Props) => {
  if (!data) return null;
  return (
    <button style={styles.pageContainer} onClick={hideMenu}>
      <div style={styles.container}>
        {data.map((item, index) => (
          <Button
            key={item.name}
            style={{
              ...styles.menuButton,
              borderTopWidth: index === 0 ? 0 : 1,
            }}
            onClick={item.onClick}
          >
            {item.name}
          </Button>
        ))}
      </div>
    </button>
  );
};

export default TopBarMenu;
