import { useContext } from "react";
import { NavigationContext, TESTING_UI } from "../../App";
import Button from "../../components/Button";
import Text from "../../components/Text";
import styles from "./landing.style";
import { PRIMARY_BUTTON } from "../../components/Button/Button";
import { CAMERA_SCREEN, ROBOT_OVERVIEW_SCREEN } from "../router";

const Landing = ({
  isHostPlayer,
  emitSocketEvent,
}: {
  isHostPlayer: boolean;
  emitSocketEvent: (event: string) => void;
}) => {
  const { setScreen, profileImage } = useContext(NavigationContext);

  return (
    <div style={styles.container}>
      <Text allCaps={true}>Wait for the other players</Text>
      {/* <img
        src={profileImage || require("../../assets/default_photo.png")}
        style={styles.profileImage}
      />
      <Button
        style={styles.takePicButton}
        type={PRIMARY_BUTTON}
        onClick={() => setScreen(CAMERA_SCREEN)}
        title={!!profileImage ? "Retake your photo" : "Take your photo"}
      /> */}
      {(isHostPlayer || TESTING_UI) && (
        <Button
          style={styles.startGameButton}
          type={PRIMARY_BUTTON}
          onClick={() => {
            emitSocketEvent("client_start_game");
            // setScreen(ROBOT_OVERVIEW_SCREEN);
          }}
          title="Start the Game"
        />
      )}
    </div>
  );
};

export default Landing;
