import queryString from "query-string";
import { useEffect, useState } from "react";
import { TESTING_UI } from "./App";
import { MESSAGE_SCREEN, ROBOT_OVERVIEW_SCREEN } from "./pages/router";
const io = require("socket.io-client");

const CONNECTION_SUCCESS = "connection_success";
const CONNECTION_ERROR = "connect_error";
const JOIN_ROOM = "join_room";
const LEFT_ROOM = "left_room";
export const GAME_EVENT = "game_event";
export const PHOTO_EVENT = "photo_event";
export const READY_CHANGE = "ready_change";

const MAIN_GAME_EVENT = "main_game_event";

const BASE_URL = "quackbox.hopto.org";

const LOCAL_URL = `http://localhost:4000`;
const DEV_URL = `http://${BASE_URL}:4000`;
const PROD_URL = `https://${BASE_URL}:3001`;
const DEFAULT_URL =
  window.location.hostname === "localhost" ? DEV_URL : PROD_URL;

const URL = queryString.parse(window.location.search)["SERVER_URL"]
  ? String(queryString.parse(window.location.search)["SERVER_URL"])
  : DEFAULT_URL;

let socket: any;

const useSockets = (
  roomCode: string,
  username: string,
  setScreen: (s: string) => void
) => {
  const [isSocketLoading, setLoading] = useState<boolean>(false);
  const [socketError, setSocketError] = useState<string | null>(null);
  const [isHostPlayer, setIsHostPlayer] = useState<boolean>(false);
  const [socketId, setSocketId] = useState<string | null>(null);
  const [connectedToRoom, setConnectedToRoom] = useState<boolean>(false);
  const [lastPacket, setLastPacket] = useState<any>(null);

  const connectToRoom = () => {
    console.log("connectToRoom");
    setLoading(true);
    setSocketError(null);

    if (TESTING_UI) {
      setSocketId("123");
      setLoading(false);
      setConnectedToRoom(true);
      return;
    }

    socket = io(
      URL,
      URL !== PROD_URL ? {} : { secure: true, withCredentials: true }
    );

    socket.on(CONNECTION_SUCCESS, (id: string) => {
      console.log("connected to server! " + id);
      setSocketId(id);
    });

    socket.on("connected_to_room", (isHost: boolean) => {
      console.log("connected_to_room; isHost: " + isHost);
      setIsHostPlayer(isHost);
      setLoading(false);
      setConnectedToRoom(true);
    });

    socket.on("connecting_to_room_failed", (isHost: boolean) => {
      console.log("connecting_to_room_failed");
      setLoading(false);
      setSocketId(null);
      setSocketError("Check your room code.");
    });

    socket.on("client_start_game", () => {
      setScreen(ROBOT_OVERVIEW_SCREEN);
    });

    socket.on(MAIN_GAME_EVENT, (event: string) => {
      const eventObj = JSON.parse(event);
      console.log("MAIN_GAME_EVENT: " + event);
      console.log("MAIN_GAME_EVENT: " + eventObj.text);
      setLastPacket(eventObj);
      setScreen(MESSAGE_SCREEN);
    });

    socket.on(CONNECTION_ERROR, (err: { message: string }) => {
      console.log(`connect_error due to ${err.message}`);
      closeConnection();
      setLoading(false);
      setSocketError("Connection failed. Check your info.");
    });

    socket.on("round_started", () => {
      setScreen(MESSAGE_SCREEN);
      console.log("Round has started!");
    });

    socket.on("round_ended", () => {
      setScreen(ROBOT_OVERVIEW_SCREEN);
      console.log("Round has ended!");
    });
  };

  useEffect(() => {
    if (TESTING_UI) return;
    if (socketId !== null) {
      socket.emit(JOIN_ROOM, { roomCode, username, id: socketId });
    }
  }, [socketId]);

  const onLeaveGame = (roomName: string, username: string) => {
    socket.emit(LEFT_ROOM, { roomName, username });
  };

  const closeConnection = () => {
    socket.disconnect();
    setSocketId(null);
  };

  const emitSocketEvent = (eventName: string, data: any) => {
    if (TESTING_UI) return;
    socket.emit("game_event", {
      eventType: eventName,
      eventData: JSON.stringify({
        id: socketId,
        username,
        ...data,
      }),
    });
  };

  console.log("socketId: " + socketId);

  return {
    socketId,
    connectToRoom,
    onLeaveGame,
    emitSocketEvent,
    closeConnection,
    isHostPlayer,
    socketError,
    isSocketLoading,
    lastPacket,
    isConnected: connectedToRoom,
  };
};

export default useSockets;
