import Camera from "./Camera";
import JoinGame from "./JoinGame";
import Landing from "./Landing";
import MessageDisplay from "./MessageDisplay";

export const JOIN_GAME_SCREEN = "JoinGame";
export const LANDING_SCREEN = "Landing";
export const ROBOT_OVERVIEW_SCREEN = "RobotOverview";
export const COMMAND_LIST_SCREEN = "CommandList";
export const CAMERA_SCREEN = "Camera";
export const MESSAGE_SCREEN = "MessageDisplay";

export interface AppScreen {
  name: string;
  page: any;
  header: string;
}

export default [
  {
    name: JOIN_GAME_SCREEN,
    page: JoinGame,
    header: "Join a game",
  },
  {
    name: LANDING_SCREEN,
    page: Landing,
    header: "Set an image",
  },
  {
    name: CAMERA_SCREEN,
    page: Camera,
    header: "Take your photo",
  },
  {
    name: MESSAGE_SCREEN,
    page: MessageDisplay,
    header: "Answer now",
  },
] as AppScreen[];
