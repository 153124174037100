import React, { useContext, useState } from "react";
import Webcam from "react-webcam";
import { NavigationContext } from "../../App";
import styles, { cameraViewport } from "./camera.style";
import Button from "../../components/Button";
import { PRIMARY_BUTTON } from "../../components/Button/Button";
import { LANDING_SCREEN } from "../router";

const videoConstraints = {
  ...cameraViewport,
  facingMode: "user",
};

const Camera = () => {
  const [capturedImage, setCapturedImage] = useState<string>("");
  const { setProfileImage, setScreen } = useContext(NavigationContext);

  const webcamRef = React.useRef<Webcam>(null);

  const capture = React.useCallback(() => {
    if (!webcamRef.current) return;
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) return;
    setCapturedImage(imageSrc);
  }, [webcamRef]);

  const reTake = () => setCapturedImage("");

  const useImage = () => {
    setProfileImage(capturedImage);
    localStorage.setItem("profileImage", capturedImage);
    setScreen(LANDING_SCREEN);
  };

  return (
    <div style={styles.container}>
      <div style={styles.previewCircle}>
        {capturedImage ? (
          <img
            alt={"your capture"}
            style={styles.capturedImage}
            src={capturedImage}
          />
        ) : (
          <Webcam
            audio={false}
            mirrored={true}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
            {...cameraViewport}
            {...{ videoConstraints }}
          />
        )}
      </div>
      <Button
        style={styles.captureButton}
        onClick={capturedImage ? reTake : capture}
        type={PRIMARY_BUTTON}
      >
        {capturedImage ? "Retake photo" : "Capture photo"}
      </Button>
      {!!capturedImage && (
        <Button
          style={styles.captureButton}
          onClick={useImage}
          type={PRIMARY_BUTTON}
        >
          {"Use this photo"}
        </Button>
      )}
    </div>
  );
};

export default Camera;
