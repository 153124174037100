import colors from "../../utils/colors";

export default {
  primaryBarButton: {
    width: "80%",
    height: 60,
    borderRadius: 30,
    borderWidth: 0,
    fontWeight: 600,
    fontSize: "1.5em",
    color: "white",
    background: colors.button,
  },
} as any;
