export default {
  page: "#191d27",
  topBar: "#24283a",
  brand: "#43e0fe",
  card: "#292e40",
  textColor: "#eee",
  button: `linear-gradient(to top, #43e0fe, #0d97f0)`,
  buttonText: "#eee",
  red: "#ff4a4a",
  blue: "#02b1fe",
  green: "#1DE9B6",
} as any;

export const tankColors = [
  "#4A14bC",
  "#01579B",
  "#02b1fe",
  "#43A047",
  "#1DE9B6",
  "#EC407A",
  "#FF3D00",
  "#D500F9",
  "#EEFF41",
  "#F9A825",
  "#ffeeff",
  "#455A64",
];

// export default {
//   page: "#eee",
//   topBar: "#0d97f0",
//   brand: "#43e0fe",
//   card: "#ddd",
//   textColor: "#222",
//   button: `linear-gradient(to top, #43e0fe, #0d97f0)`,
//   buttonText: "#eee",
//   red: "#ff4a4a",
//   blue: "#02b1fe",
//   green: "#1DE9B6",
// } as any;
