import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useContext, useState } from "react";
import { NavigationContext } from "../../App";
import colors from "../../utils/colors";
import TopBarMenu from "../TopBarMenu";
import styles from "./headerBar.style";
import { JOIN_GAME_SCREEN } from "../../pages/router";

interface Props {
  title: string;
  closeConnection: () => void;
}

const HeaderBar = ({ title, closeConnection }: Props) => {
  const { setScreen, profileImage } = useContext(NavigationContext);
  const [showMenu, setShowMenu] = useState(false);

  const onDisconnectPress = () => {
    closeConnection();
    setScreen(JOIN_GAME_SCREEN);
  };

  const menuItems = [
    { name: "Help", onClick: () => {} },
    { name: "Disconnect", onClick: onDisconnectPress },
    { name: "Visit Site", onClick: () => {} },
  ];

  const hideMenu = () => setShowMenu(false);

  return (
    <>
      <div style={styles.container}>
        {!!profileImage && (
          <img
            alt={"Profile pic"}
            style={styles.profileImage}
            src={profileImage}
          />
        )}
        <span style={styles.title}>{title}</span>
        <FontAwesomeIcon
          onClick={() => setShowMenu(!showMenu)}
          style={styles.menuButton}
          size={"2x"}
          color={colors.buttonText}
          icon={faEllipsisV}
        />
      </div>
      {showMenu && <TopBarMenu data={menuItems} {...{ hideMenu }} />}
    </>
  );
};

export default memo(HeaderBar);
