import colors from "../../utils/colors";

export default {
  container: {
    position: "relative",
    width: "100%",
    height: "100vh",
    overflowY: "auto",
    backgroundColor: colors.page,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
  },
  answerInput: {
    width: "80%",
    height: 60,
    borderRadius: 30,
    borderWidth: 0,
    paddingLeft: 30,
    fontWeight: 600,
    fontSize: 24,
    backgroundColor: colors.card,
    marginBottom: 50,
    color: colors.textColor,
  },
} as any;
