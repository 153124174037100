import colors from "../../utils/colors";

const CAMERA_VIEW_SIZE = 200;
export const cameraViewport = {
  width: CAMERA_VIEW_SIZE,
  height: CAMERA_VIEW_SIZE,
};

export default {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "92vh",
    backgroundColor: colors.page,
  },
  previewCircle: {
    width: CAMERA_VIEW_SIZE,
    height: CAMERA_VIEW_SIZE,
    borderRadius: CAMERA_VIEW_SIZE / 2,
    marginTop: 50,
    overflow: "hidden",
    backgroundColor: "black",
  },
  capturedImage: {
    width: CAMERA_VIEW_SIZE,
    height: CAMERA_VIEW_SIZE,
  },
  captureButton: {
    marginTop: 40,
  },
} as any;
