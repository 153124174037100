import React, { useEffect, useState } from "react";
import Text from "../../components/Text";
import styles from "./messageDisplay.style";
import Button from "../../components/Button";
import { PRIMARY_BUTTON } from "../../components/Button/Button";

interface Props {
  message: string;
  emitSocketEvent: any;
  lastPacket: any;
}

const MessageDisplay = ({ message, emitSocketEvent, lastPacket }: Props) => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [type, setType] = useState("multiple");
  const [optionsEnabled, setOptionsEnabled] = useState(true);

  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if ("vibrate" in navigator) {
      navigator.vibrate(200);
    }

    const parsedData = JSON.parse(lastPacket.text);
    const question = parsedData.question;
    const options = parsedData.options;
    const type = parsedData.type;

    setQuestion(question);
    setOptions(options);
    setType(type);
    setOptionsEnabled(true);

    console.log(">>>lastPacket.text: ", lastPacket.text);
  }, [lastPacket]);

  if (type === "image") {
    return (
      <>
        <div style={styles.container}>
          <input
            placeholder="Write your answer..."
            style={styles.answerInput}
            value={inputText}
            maxLength={12}
            onChange={(e) => setInputText(e.target.value?.toUpperCase())}
          />
          <Button
            disabled={!optionsEnabled}
            style={{ marginTop: 20 }}
            onClick={() => {
              emitSocketEvent("answer_question", { answerString: inputText });
              setInputText("");
            }}
            type={PRIMARY_BUTTON}
          >
            SEND
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div style={styles.container}>
        <Text
          style={{
            marginBottom: 40,
            marginLeft: 20,
            marginRight: 20,
            textAlign: "center",
          }}
        >
          {decodeHtmlEntities(question)}
        </Text>
        {["A", "B", "C", "D"].map((letter, index) => (
          <Button
            key={index}
            disabled={!optionsEnabled}
            style={{ marginBottom: 20 }}
            onClick={() => {
              emitSocketEvent("answer_question", { answerString: `${index}` });
              setOptionsEnabled(false);
            }}
            type={PRIMARY_BUTTON}
          >
            {`${letter}. ${decodeHtmlEntities(options[index])}`}
          </Button>
        ))}
      </div>
    </>
  );
};

function decodeHtmlEntities(input: string) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default MessageDisplay;
