import colors from "../../utils/colors";

export default {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "92vh",
    backgroundColor: colors.page,
  },
  inputContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 80,
  },
  connectionFailed: {
    position: "absolute",
    bottom: 112,
    color: colors.red,
  },
  connectingToRoom: {
    position: "absolute",
    bottom: 112,
    color: "white",
  },
  loadIcon: {
    color: "white",
    width: 30,
    height: 30,
  },
  nameInput: {
    width: "100%",
    height: 60,
    borderRadius: 30,
    borderWidth: 0,
    paddingLeft: 30,
    fontWeight: 600,
    fontSize: 24,
    backgroundColor: colors.card,
    marginBottom: 50,
    color: colors.textColor,
  },
  submitButton: {
    width: "100%",
  },
} as any;
