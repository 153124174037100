import colors from "../../utils/colors";

export default {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%",
    height: "92vh",
    backgroundColor: colors.page,
    paddingTop: 50,
  },
  profileImage: {
    width: 200,
    height: 200,
    borderRadius: 100,
    backgroundColor: "white",
    marginTop: 40,
  },
  takePicButton: {
    marginTop: 40,
  },
  startGameButton: {
    marginTop: 20,
  },
} as any;
