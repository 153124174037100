import Button from "../../components/Button";
import { PRIMARY_BUTTON } from "../../components/Button/Button";
import styles from "./joinGame.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface Props {
  username: string;
  setUsername: (name: string) => void;
  roomCode: string;
  setRoomCode: (name: string) => void;
  connectToRoom: () => void;
  isSocketLoading: boolean;
  socketError: string | null;
}

const JoinGame = ({
  username,
  setUsername,
  roomCode,
  setRoomCode,
  connectToRoom,
  socketError,
  isSocketLoading,
}: Props) => {
  const onSubmit = () => {
    if (username.length >= 2) {
      localStorage.setItem("username", username);
      connectToRoom();
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <input
          placeholder="Enter 4-letter code..."
          style={styles.nameInput}
          value={roomCode}
          maxLength={4}
          onChange={(e) => setRoomCode(e.target.value?.toUpperCase())}
        />
        <input
          placeholder="Enter your name..."
          style={styles.nameInput}
          value={username}
          maxLength={12}
          onChange={(e) => setUsername(e.target.value?.toUpperCase())}
        />
        {isSocketLoading ? (
          <FontAwesomeIcon
            spin={true}
            style={styles.loadIcon}
            icon={faSpinner}
          />
        ) : (
          <Button
            title={`LET'S GO!`}
            onClick={onSubmit}
            type={PRIMARY_BUTTON}
            style={styles.submitButton}
          />
        )}
        {socketError && (
          <div style={styles.connectionFailed}>{socketError}</div>
        )}
        {isSocketLoading && (
          <div style={styles.connectingToRoom}>Connecting to room...</div>
        )}
      </div>
    </div>
  );
};

export default JoinGame;
