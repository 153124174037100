import colors from "../../utils/colors";
import { TOP_BAR_HEIGHT } from "../HeaderBar/headerBar.style";

export default {
  pageContainer: {
    zIndex: 199,
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "transparent",
    borderWidth: 0,
  },
  container: {
    zIndex: 200,
    position: "absolute",
    top: TOP_BAR_HEIGHT + 1,
    right: 0,
    backgroundColor: colors.topBar,
    display: "flex",
    flexDirection: "column",
  },
  menuButton: {
    boxShadow: undefined,
    borderTopWidth: 1,
    borderStyle: "solid",
    fontSize: "1.21em",
    color: colors.textColor,
    backgroundColor: colors.topBar,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 16,
    justifyContent: "flex-start",
  },
} as any;
